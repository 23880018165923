(function($){
    // Mouse move animation
    $(document).on('mousemove', function(e){
        let x = e.clientX;
        let y = e.clientY;
        $('.mouse-move').css("transform", `translateY(${-y*0.05}px) translateX(${-x*0.05}px)`);
        $('.mouse-move-1').css("transform", `rotateY(${x*0.015}deg) rotateX(${y*0.015}deg)`);
        $('.mouse-move-2').css("transform", `translateY(${x*0.015}px) translateX(${y*0.005}px)`);
        $('.mouse-move-3').css("transform", `translateX(${x*0.015}px) translateY(${y*0.005}px)`);
        $('.mouse-move-4').css("transform", `translateY(${x*0.005}px) translateX(${y*0.015}px)`);
        $('.mouse-move-5').css("transform", `translateX(${x*0.05}px) translateY(${y*0.015}px)`);
    });
    
    // Impacts area
    $('.all-impacts .impact-item').each(function(){
        $(this).on('mouseenter', function(){
            $('.all-impacts .impact-item.active').removeClass('active');
            $(this).addClass('active');
        });
    });

    // Fast facts slider
    $('.all-fast-facts-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 0,
        infinite: false,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    });

})(jQuery);